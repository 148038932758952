<template xmlns:bind="http://www.w3.org/1999/xhtml">
  <div class="identity-certify">
    <v-header/>
    <div class="certify-logo">
      <div class="certify-logo-left">
        <img src="@/assets/svg/security.png"/>认证身份
      </div>
      <div class="certify-logo-right">认证后凭名片海报进群</div>
    </div>


    <div class="certify-card">
      <div class="certify-form-item">
        <div class="certify-form-item-label">
          <div class="red-dot"></div>
          姓
          <div style="width: 28px;"></div>
          名
        </div>
        <div class="certify-form-item-value">
          <input v-model="model.name" placeholder="真实姓名提高合作可信度" class="input" type="text"/>
        </div>
      </div>

      <div class="certify-form-item">
        <div class="certify-form-item-label">
          <div class="red-dot"></div>
          组
          <div style="width: 28px;"></div>
          织
        </div>
        <div class="certify-form-item-value">
          <input v-model="model.organization" placeholder="工作的单位 / 负责的项目" class="input" type="text"/>
        </div>
      </div>

      <div class="certify-form-item">
        <div class="certify-form-item-label">
          <div class="red-dot"></div>
          身
          <div style="width: 28px;"></div>
          份
        </div>
        <div class="certify-form-item-value">
          <input v-model="model.identity" placeholder="职位 / 项目角色 / 业务分管" class="input" type="text"/>
        </div>
      </div>
    </div>


    <div class="certify-card">
      <div class="certify-form-item">
        <div class="certify-form-item-label">
          <div class="red-dot hide-dot"></div>
          我的业务
        </div>
        <div class="certify-form-item-value">
          <input v-model="model.business" placeholder="进群能提供" class="input" type="text"/>
        </div>
      </div>
      <div class="certify-form-item">
        <div class="certify-form-item-label">
          <div class="red-dot hide-dot"></div>
          合作诉求
        </div>
        <div class="certify-form-item-value">
          <input v-model="model.needs" placeholder="进群能诉求" class="input" type="text"/>
        </div>
      </div>
      <van-uploader :after-read="uploadNameCard">
        <div class="certify-form-item" style="align-items: flex-start" bindtap="uploadNameCard">
          <div class="certify-form-item-label">
            <div class="red-dot hide-dot"></div>
            名片上传
          </div>
          <div class="certify-form-item-value name-card">
            <img v-if="model.nameCardUrl && model.nameCardUrl.length" :src="model.nameCardUrl"/>
            <img mode="widthFix" v-else src="@/assets/svg/name-card.svg"/>
            <div class="input-btn">点击上传</div>
          </div>
        </div>
      </van-uploader>
    </div>


    <div class="certify-card">
      <div class="certify-form-item">
        <div class="certify-form-item-label">
          <div class="red-dot"></div>
          手
          <div style="width: 28px;"></div>
          机
        </div>
        <div class="certify-form-item-value mobile-input">
          <input v-model="model.mobile" placeholder="可联系手机" class="input" type="text"/>
          <button @click="autoSetMobile" class="input-btn">自动获取</button>
        </div>
      </div>
      <div class="certify-form-item">
        <div class="certify-form-item-label">
          <div class="red-dot"></div>
          微
          <div style="width: 28px;"></div>
          信
        </div>
        <div class="certify-form-item-value mobile-input">
          <input v-model="model.wxNum" placeholder="可联系微信" class="input" type="text"/>
          <button class="input-btn" @click="setWxNumSameAsMobile">同手机号</button>
        </div>
      </div>
      <div class="certify-form-item">
        <div class="certify-form-item-label">
          <div class="red-dot hide-dot"></div>
          办公访址
        </div>
        <div class="certify-form-item-value">
          <input v-model="model.companyAddress" placeholder="如欢迎拜访交流请填写" class="input" type="text"/>
        </div>
      </div>
    </div>

    <div class="primary-btn" @click="genPost">生成海报进群</div>
    <identity-certify-share-post-dialog ref="identityCertifySharePostDialog"/>
  </div>
</template>


-------------------- script --------------------

<script>
  import {computeObjectFullPath, multipartPutObject} from "../../api/oss";
  import {mapGetters} from 'vuex'
  import {identityCertifyV2Api} from "../../api/v2/identityCertifyV2Api";
  import IdentityCertifySharePostDialog from '@/components/IdentityCertifySharePostDialog';

  export default {
    computed: {...mapGetters(['userInfo'])},
    components:{IdentityCertifySharePostDialog},
    data() {
      return {
        model: {
          id: '',
          name: '',
          organization: '',
          identity: '',
          business: '',
          needs: '',
          nameCardUrl: '',
          mobile: '',
          wxNum: '',
          companyAddress: ''
        }
      }
    },


    created() {
      this.loadData();
    },


    methods: {
      // 上传名片
      async uploadNameCard({file}) {
        const {id} = this.userInfo;
        const timestamp = Date.now();
        const objectName = `identityCertify/${id}/${timestamp}.png`;
        await multipartPutObject(file, objectName);
        // console.log(computeObjectFullPath(objectName));
        this.model.nameCardUrl = computeObjectFullPath(objectName);
        this.$forceUpdate();
      },


      // 加载我的数据
      async loadData() {
        this.model = await identityCertifyV2Api.findMy();
      },


      // 保存并生成海报
      async genPost() {
        if (!this.model.name || !this.model.name.length) {
          return this.$toast('请填写姓名');
        }
        if (!this.model.organization || !this.model.organization.length) {
          return this.$toast('请填写组织');
        }
        if (!this.model.identity || !this.model.identity.length) {
          return this.$toast('请填写身份');
        }
        if (!this.model.mobile || !this.model.mobile.length) {
          return this.$toast('请填写手机号码');
        }
        if (!this.model.wxNum || !this.model.wxNum.length) {
          return this.$toast('请填写微信号');
        }
        // 首先要保存
        await identityCertifyV2Api.saveMy(this.model);
        this.$refs.identityCertifySharePostDialog.show();
      },


      // 自动设置手机号
      autoSetMobile() {
        this.model.mobile = this.userInfo.mobile;
      },


      // 设置微信号和手机号相同
      setWxNumSameAsMobile() {
        if (!this.model.mobile || !this.model.mobile.length) {
          return this.$toast({title: '请填写手机号码', icon: 'none'})
        }
        this.model.wxNum = this.model.mobile;
      },
    }
  }
</script>


-------------------- style --------------------


<style scoped lang="less">
  .identity-certify {
    background: #f7f7f7;
    padding-bottom: 100px;
    padding-top: 40px;
  }

  .certify-logo {
    height: 48px;
    background-color: rgba(21, 29, 54, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 27px;
  }

  .certify-logo-left {
    font-size: 14px;
    font-weight: bolder;
    color: rgba(254, 224, 159, 1);
    display: flex;
    align-items: center;
  }

  .certify-logo-left img {
    height: 25px;
    width: 25px;
    margin-right: 6px;
  }

  .certify-logo-right {
    opacity: 0.3;
    color: rgba(255, 255, 255, 1);
    font-size: 12px;
  }


  .certify-card {
    padding: 20px 27px;
    background: #fff;
    font-size: 14px;
    margin-bottom: 14px;
  }

  .red-dot {
    height: 4px;
    width: 4px;
    border-radius: 500px;
    background-color: #ee4a50;
    margin-right: 4px;
  }

  .hide-dot {
    background: #fff;
  }

  .certify-form-item {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
  }

  .certify-form-item-label {
    width: 77px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  .certify-form-item-value {
    flex-grow: 1;
    position: relative;
    display: flex;
  }

  .certify-form-item-value .input {
    height: 32px;
    background: #f3f3f5;
    padding: 0 12px;
    border-radius: 5px;
    border: none;
    flex-grow: 1;
  }


  .certify-form-item-value .placeholder {
    color: rgba(21, 29, 54, 0.3);
  }


  .name-card {
    flex-grow: 1;
    background: rgba(21, 29, 54, 0.05);
    border-radius: 5px;
    overflow: hidden;
  }

  .name-card img {
    width: 244px;
    height: calc(240px * 144 / 240);
    transform: scale(1.05);
  }


  .input-btn {
    width: 60px !important;
    height: 20px !important;
    background: #151d36;
    color: #fff;
    border-radius: 50px;
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 10px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    padding: 0;
    font-weight: normal !important;
  }


  .primary-btn {
    width: 270px;
    height: 45px;
    background: #151d36;
    color: #fff;
    border-radius: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
  }

  .mobile-input .input {
    padding-right: 75px;
  }

</style>
