import {v2Request} from "./v2Request";


export const identityCertifyV2Api = {

  // 查找我的用户认证信息
  async findMy() {
    return v2Request.get(`identityCertify/findMy`);
  },

  // 保存
  async saveMy(form) {
    return v2Request.post(`identityCertify/saveMy`, form);
  }

};
